<template>
  <div class="login-wrap">
    <div v-if="!isThird" class="top-logo">
      <img src="https://cdn.kuaidi100.com/images/singlelogo.png">
    </div>
    <div class="login-title">
      {{ isThird ? "请先验证手机号":text[type].label }}
    </div>
    <div class="login-form">
      <div class="f-ctrl">
        <input v-model.trim="form.input" type="text" :placeholder="text[type].placeholder">
      </div>
      <div v-show="valid && type=='account'" class="f-ctrl">
        <input v-model.trim="form.valid" type="text" placeholder="输入图片验证码">
        <img v-if="type=='mobile'" :src="'https://m.kuaidi100.com/pcweb/verifycode/generateverifycode?method=getVerification&random='+random + '&name='+form.input" alt="验证码" @click="random = Math.random()">
        <img v-if="valid && type=='account'" :src="'https://m.kuaidi100.com/pcweb/verifycode/generateverifycode?method=make&random='+random + '&name='+form.input" alt="验证码" @click="random = Math.random()">
      </div>
      <div class="f-ctrl">
        <input v-show="type=='account'" v-model.trim="form.pass" type="password" :placeholder="text[type].pls">
        <input v-show="type=='mobile'" v-model.trim="form.code" type="text" :placeholder="text[type].pls">
        <span v-show="type=='mobile'" @click="send">{{ code.second==60 ? '获取验证码': '重新发送'+code.second + 's' }}</span>
      </div>
    </div>
    <div>
      <span class="check-pre-tip" v-show="showTipsFlag">请阅读同意后登录</span>
      <div class="agreement">
        <i :class="{active: activeFlag}" @click="checkChange"></i>
        已阅读并同意快递100
        <a href="https://m.kuaidi100.com/activity/snth5/setcompacts.html?termId=20004001&termType=20">《用户协议》</a>与
        <a href="https://m.kuaidi100.com/activity/snth5/setcompacts.html?termId=10003001&termType=10">《隐私政策》</a>
      </div>
    </div>
    
    <div v-show="type=='mobile'" class="register-tips">
      未注册用户登录默认注册快递100账号
    </div>
    <div class="logo-sub-btn" :class="{disabled: login.loading}" @click="submit">
      {{ isThird ? "验证" : login.text }}
    </div>
    <div v-if="!isThird" class="toggle-type" @click="toggle">
      {{ text[type].toggle }}
    </div>
  </div>
</template>
<script>
import util from "lib/util"
export default {
  data: function(){
    return {
      type: 'mobile',
      text: {
        account: {
          placeholder: '请输入登录账号',
          toggle: '手机验证码登录', 
          label: '账号密码登录',
          atip: '请输入登录账号',
          btip: '请输入密码',
          pls:'请输入密码'
        },
        mobile: {
          placeholder: '请输入手机号', 
          toggle: '账号密码登录',
          label:'手机验证码登录',
          atip: '请输入正确的手机号', 
          btip: '请输入验证码',
          pls:'请输入短信验证码'
        }
      },
      isThird: !!this.$store.state.coname,
      mobileReg: /^1\d{10}$/,
      random: Math.random(),
      code: {second: 60},
      valid:false,
      form: {input: '' ,pass: '',code: '',valid:''},
      login: {text: '登录',loading: false},
      activeFlag: false,
      txData: {},
      showTipsFlag: false,
    }
  },
  methods: {
    checkChange() {
      this.activeFlag = !this.activeFlag
      if(this.activeFlag) {
        this.showTipsFlag = false
      }
    },
    showValid: function() {
      this.txData = {}
      try {
        var captcha = new TencentCaptcha('199204811', this.callbackNew, {
          userLanguage: 'zh-cn',
          showFn: (ret) => {
            const {
              duration, // 验证码渲染完成的耗时(ms)
              sid, // 链路sid
            } = ret;
          },
        });
        // 调用方法，显示验证码
        captcha.show();
      } catch (error) {
        // 加载异常，调用验证码js加载错误处理函数
        this.loadErrorCallback();
      }
    },
    loadErrorCallback: function(e) {
      console.log('loadErrorCallback', e)
    },
    callbackNew: function(e) {
      console.log('callbackNew', e)
      this.txData = e
      if (e.ret === 0 && e.ticket && e.randstr) { // 验证通过 ret为0是验证成功 为2是用户关闭验证码
        // $('#warn').hide()
        // send()
        this.newSendCode(e)
      } else {
        // showError('验证错误，请重试')
        this.$toast('验证错误，请重试')
      }
    },
    send: function() {
      if (!this.validate('send')) return
      if (this.txData.ret === 0 && this.txData.ticket && this.txData.randstr) {
        this.newSendCode(this.txData)
      } else {
        this.showValid()
      }
    },
    validate: function(type) {
      if (!this.form.input) {
        this.$toast(this.text[this.type].atip, 1000);
        return false;
      }
      switch (type) {
        case 'send':
        case 'phone':
          if (!this.mobileReg.test(this.form.input)) {
            this.$toast('请输入正确的手机号');
            return false;
          }
          if (type == 'phone' && !this.form.code) {
            this.$toast('请输入短信验证码');
            return false;
          }
          break;
        case 'account':
          if (!this.form.pass) {
            this.$toast(this.text[this.type].btip, 1000);
            return false;
          }
          break;
      }
      return true;
    },
    newSendCode: function(txData) {
      if (this.code.second != 60) {
        this.$toast('验证码一分钟内不能重复发送')
        return;
      }
      var data = {
        name: this.form.input,
        validType: 1,
        ticket: txData.ticket || '',
        randStr: txData.randstr || ''
      };
      this.$http.post('/pcweb/sms/loginsendsms', {
        data: data,
        handleFail: false
      }).then(rst => {
        if (rst.status == 200) {
          this.$toast('验证码已经发送到您的手机')
          return this.calcTime()
        }
      }).catch((rst)=>{
          if (rst.status == 10010 || rst.status == 10011) {
            // this.$toast("请输入图片验证码")
            this.showValid()
          } else {
            this.$toast(rst.message)
          }
      })
    },
    toggle: function() {
      this.type = this.type == 'account' ? 'mobile' : 'account'
      if(this.type === 'mobile' && !/^1\d{1,10}$/.test(this.form.input)) {
        this.form.input = ''
      }
    },
    submit: function(){
      var data = this.type == 'account' ? {
        name: this.form.input,
        password: this.form.pass,
        validcode: this.form.valid || ''
      } : {
        phone: this.form.input,
        code: this.form.code,
        source: "mexpress"
      }
      if(this.login.loading) return
      const url  = this.type == 'account' ? '/pcweb/login/weblogin?method=weblogin' : "/pcweb/login/webmobilelogin?method=webclogin"
      if(this.validateLogin()){
        if(!this.activeFlag) {
          this.showTipsFlag = true
          return
        }
        this.login.text = this.isThird ? "正在验证..." : "正在登录..."
        this.login.loading = true;
        this.$http.post(url, {
          data: data
        }).then(rst => {
          var store = this.$store.state.globalData
          util.setcookie("TOKEN", rst.token)
          this.$store.commit("setGlobal", ({token: rst.token}))
          if(store.coname && store.appid && store.openid || store.isWechat && !store.isMiniProgram) {//第三方接入用户和快递100公众号登录，则记录会话的本地存储
            util.setSession("TOKEN", rst.token)
          } else {//第三方公众号接入且没有接入用户信息的，则记录长期的TOKEN，在个人中心提供退出登录功能供用户自行退出
            util.setcookie('TOKEN', rst.token)
          }
          if(this.$route.query.redirect && this.from.name) {
            this.navigator.replace(util.singleClone(this.from))
          } else {
            this.$router.go(-1)
          }
        }).catch(rst => {
          this.login.loading = false;
          this.login.text = "登录"
          this.$toast(rst.message)
          if(rst.isvalid == "1") {
            this.valid = true
            this.random = Math.random()
          } else {
            this.valid = false
          }
        })
      }
    },
    validateLogin: function(){
      if(!this.form.input) {
        return this.$toast(this.text[this.type].atip, 1000)
      } else if(this.type == 'mobile'){
        if(!this.mobileReg.test(this.form.input)) {
          return this.$toast(this.text['mobile'].atip, 1000)
        }
        if(this.smsvalid && !this.form.valid) {
          return this.$toast("请输入图片验证码")
        }
        if(!this.form.code) {
          return this.$toast(this.text['mobile'].btip, 1000)
        }
      } else if(this.type == 'account') {
        if(this.valid && !this.form.valid) {
          return this.$toast("请输入图片验证码", 1000)
        }
        if(!this.form.pass) {
          return this.$toast(this.text[this.type].btip, 1000)
        }
      }
      return true;
    },
    sendCode:function() {
      if(this.code.second != 60) return;
      if(!this.mobileReg.test(this.form.input)) {
        return this.$toast('请输入正确的手机号码', 1000)
      }
      this.$http.post('/pcweb/sms/loginsendsms', {
        data: {
          name: this.form.input,
          validcode:this.form.valid
        },
        handleFail: false
      }).then(() => {
        this.$toast("验证码已经发送到您的手机")
        this.calcTime()
      }).catch(r => {
        if(r.status == '10010') {
          this.$toast("请输入图片验证码")
        } else if(r.status == '10011'){
          this.$toast("图片验证码错误",1000)
        } else {
          this.$toast(r.message)
        }
      })
    },
    calcTime: function(){
      this.calcTimer && clearInterval(this.calcTimer);
      this.calcTimer = setInterval(() => {
        if(!this.code.second--){
          clearInterval(this.calcTimer)
          this.calcTimer = null
          this.code.second = 60
        }
      }, 1000)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm =>{
      vm.from = from
    })
  }
}
</script>
<style scoped>
.login-wrap {
  padding: 3.5rem .875rem 1rem;
  box-sizing: border-box;
  line-height: 1em;
  font-size: .875rem;
  text-align: center;
}
.top-logo img {
  max-width: 8rem;
}
.login-title {
  margin: 2rem 0 1rem;
  font-size: 1rem;
}
.login-form {
  border: 1px solid #efeff4;
}
.f-ctrl {
  position: relative;
  padding-left: 1em;
  display: flex;
  background: #fff;
  justify-content: center;
}
.f-ctrl img{
  position: absolute;
  right: 5px;
  top: 6px;
  width: 80px;
}
.f-ctrl + .f-ctrl {
  border-top: 1px solid #efeff4;
}
.f-ctrl input {
  display: block;
  flex: 1;
  padding: 1em 0;
}
.f-ctrl span {
  position: absolute;
  right: 1em;
  top: 1.5em;
  color: #3278e6;
  font-size: .8em;
}
.logo-sub-btn {
  margin-top: 1em;
  background: #ff8522;
  text-align: center;
  line-height: 3em;
  font-size: 1rem;
  color: #FFF;
}
.toggle-type {
  padding: 1em 0;
  color: #317ee7;
  text-align: right;
}
.register-tips {
  margin-top: 15px;
  margin-bottom: -10px;
  color: #888;
  font-size: .75rem;
}
.agreement {
  color: #888;
  margin-top: 4px;
  margin-left: 0.8em;
  padding-left: 5px;
  font-size: 12px;
  text-wrap: nowrap;
  word-break: keep-all;
}
.agreement i {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: -2px;
  border: 1px solid #aaa;
  cursor: pointer;
  border-radius: 2px;
}
.agreement i.active {
  background: url(https://cdn.kuaidi100.com/images/sso/login.png?version=20171114)
    0 -105px #3681e4 no-repeat;
  border-color: #3681e4;
}
.check-pre-tip {
  display: block;
  position: relative;
  color: #fff;
  width: fit-content;
  padding: 4px 6px;
  background-color: #5b5d5f;
  border-radius: 6px;
  margin: 2px;
  margin-left: 0.8em;
  left: 14px;
}
.check-pre-tip::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 5px;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #5b5d5f transparent transparent transparent;
}
</style>